
        import * as THREE from '/node_modules/three/build/three.module.js';
        import { GUI } from '/node_modules/three/examples/jsm/libs/lil-gui.module.min.js';
        import { OrbitControls } from '/node_modules/three/examples/jsm/controls/OrbitControls.js';
        import { GroundedSkybox } from '/node_modules/three/examples/jsm/objects/GroundedSkybox.js';
        import { GLTFLoader } from '/node_modules/three/examples/jsm/loaders/GLTFLoader.js';
        import { DRACOLoader } from '/node_modules/three/examples/jsm/loaders/DRACOLoader.js';
        import { RGBELoader } from '/node_modules/three/examples/jsm/loaders/RGBELoader.js';

        const params = {
            height: 15,
            radius: 100,
            enabled: true,
        };

        let camera, scene, renderer, skybox;
        let currentHDR = '/public/blouberg_sunrise_2_1k.hdr';


        const lightingPresets = {
            '/public/blouberg_sunrise_2_1k.hdr': {
                toneMappingExposure: 1.2,
                rotation: 0,
                position: (-5, 12, 0),
                shadowMapSize: { width: 1000, height: 1000 }
            },
            '/public/drackenstein_quarry_2k.hdr': {
                toneMappingExposure: 1.0,
                rotation: 0,
                position: (-5, 15, 0),
                shadowMapSize: { width: 500, height: 500 }
            },
            'whiteStudio': {
                toneMappingExposure: 1.0,
                position: (0, 15, 0),
                shadowMapSize: { width: 120, height: 120 }
            }
        };

        init().then(render);

        async function init() {
            camera = new THREE.PerspectiveCamera(
                40,
                window.innerWidth / window.innerHeight,
                1,
                1000
            );
            camera.position.set(20, 50, 50);
            camera.lookAt(0, 4, 0);

            scene = new THREE.Scene();

            const hdrLoader = new RGBELoader();
            const envMap = await hdrLoader.loadAsync(currentHDR);
            envMap.mapping = THREE.EquirectangularReflectionMapping;

            skybox = new GroundedSkybox(envMap, params.height, params.radius);
            skybox.position.y = params.height - 0.01;
            scene.add(skybox);

            scene.environment = envMap;

            const dracoLoader = new DRACOLoader();
            dracoLoader.setDecoderPath('/node_modules/three/examples/jsm/libs/draco/gltf/');

            const loader = new GLTFLoader();
            loader.setDRACOLoader(dracoLoader);

            loader.load('/public/tokyo2.glb', function (gltf) {
                const tkModel = gltf.scene;
                tkModel.scale.multiplyScalar(15);
                tkModel.rotation.y = Math.PI / 3;

                tkModel.traverse(function (node) {
                    if (node.isMesh) {
                        node.castShadow = true;
                        node.receiveShadow = false;
                    }
                });

                scene.add(tkModel);
                render();
                hideLoadingScreen();
            }, onProgress);

            renderer = new THREE.WebGLRenderer({ antialias: true });
            renderer.setPixelRatio(window.devicePixelRatio);
            renderer.setSize(window.innerWidth, window.innerHeight);
            renderer.toneMapping = THREE.ACESFilmicToneMapping;
            renderer.shadowMap.enabled = true;
            renderer.shadowMap.type = THREE.PCFSoftShadowMap;

            const initialPreset = lightingPresets[currentHDR];
            if (initialPreset) {
                renderer.toneMappingExposure = initialPreset.toneMappingExposure;
            }

            const controls = new OrbitControls(camera, renderer.domElement);
            controls.addEventListener('change', render);
            controls.target.set(0, 7.5, 0);
            controls.maxPolarAngle = THREE.MathUtils.degToRad(90);
            controls.maxDistance = 80;
            controls.minDistance = 20;
            controls.enablePan = false;
            controls.update();

            document.body.appendChild(renderer.domElement);
            window.addEventListener('resize', onWindowResize);

            const directionalLight = new THREE.DirectionalLight(0xffffff, 1);
            directionalLight.position.set(-5, 15, 0);
            directionalLight.castShadow = true;
            directionalLight.shadow.mapSize.width = 1000;
            directionalLight.shadow.mapSize.height = 1000;
            directionalLight.shadow.camera.top = 200;
            directionalLight.shadow.camera.bottom = -200;
            directionalLight.shadow.camera.left = -200;
            directionalLight.shadow.camera.right = 200;
            directionalLight.shadow.camera.near = -200;
            directionalLight.shadow.camera.far = 200;
            scene.add(directionalLight);

            const floorGeometry = new THREE.PlaneGeometry(100, 100);
            const floorMaterial = new THREE.ShadowMaterial({ opacity: 0.2 });
            const floor = new THREE.Mesh(floorGeometry, floorMaterial);
            floor.rotation.x = - Math.PI / 2;
            floor.position.y = 0;
            floor.receiveShadow = true;
            scene.add(floor);

            renderer.shadowMap.enabled = true;



            window.switchToWhiteStudio = function () {
                try {
                    scene.background = null;
                    scene.children = scene.children.filter(child => !(child.isAmbientLight));

                    const preset = lightingPresets['whiteStudio'];
                    scene.background = new THREE.Color(0xd0d0d0);

                    scene.environment = null;
                    if (skybox) {
                        scene.remove(skybox);
                        skybox = null;
                    }
                    if (preset) {
                        renderer.toneMappingExposure = preset.toneMappingExposure;
                        directionalLight.shadow.mapSize.width = preset.shadowMapSize.width;
                        directionalLight.shadow.mapSize.height = preset.shadowMapSize.height;
                        directionalLight.shadow.map = null;
                        directionalLight.position.set = preset.position;
                    }

                    const ambientLight = new THREE.AmbientLight(0xffffff, 3.3);
                    scene.add(ambientLight);

                    document.querySelectorAll('#hdrButtons button').forEach(btn => {
                        btn.classList.remove('active');
                    });
                    document.querySelector('#hdrButtons button[data-hdr="whiteStudio"]').classList.add('active');

                    render();
                    
                } catch (error) {
                    console.error('Error switching to white studio:', error);
                }
            }



            window.switchHDR = async function (hdrPath) {
                try {
                    scene.background = null;
                    scene.AmbientLight = null;
                    scene.children = scene.children.filter(child => !(child.isAmbientLight));

                    const hdrLoader = new RGBELoader();
                    hdrLoader.setDataType(THREE.FloatType);

                    const newEnvMap = await hdrLoader.loadAsync(hdrPath);
                    newEnvMap.mapping = THREE.EquirectangularReflectionMapping;
                    const preset = lightingPresets[hdrPath];

                    if (preset) {
                        newEnvMap.rotation = preset.rotation;
                        newEnvMap.needsUpdate = true;
                    }

                    scene.environment = newEnvMap;
                    renderer.render(scene, camera);

                    const newSkybox = new GroundedSkybox(newEnvMap, params.height, params.radius);
                    newSkybox.position.y = params.height - 0.01;

                    if (preset) {
                        newSkybox.rotation.y = preset.rotation;
                    }
                    scene.remove(skybox);
                    scene.add(newSkybox);
                    skybox = newSkybox;

                    if (preset) {
                        renderer.toneMappingExposure = preset.toneMappingExposure;
                        directionalLight.shadow.mapSize.width = preset.shadowMapSize.width;
                        directionalLight.shadow.mapSize.height = preset.shadowMapSize.height;
                        directionalLight.shadow.map = null;
                        directionalLight.position.set = preset.position;
                    }

                    document.querySelectorAll('#hdrButtons button').forEach(btn => {
                        btn.classList.remove('active');
                        if (btn.getAttribute('onclick').includes(hdrPath)) {
                            btn.classList.add('active');
                        }
                    });

                    currentHDR = hdrPath;
                    render();
                    
                } catch (error) {
                    console.error('Error loading HDR:', error);
                }
            }

            document.querySelector('#hdrButtons button').classList.add('active');
        }

        function onWindowResize() {
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.updateProjectionMatrix();
            renderer.setSize(window.innerWidth, window.innerHeight);
            render();
        }

        function render() {
            renderer.render(scene, camera);
        }

        function onProgress(xhr) {
            if (xhr.lengthComputable) {
                const percentComplete = (xhr.loaded / xhr.total) * 100;
                document.getElementById('progressBarFill').style.width = percentComplete + '%';
            }
        }

        function hideLoadingScreen() {
            const loadingScreen = document.getElementById('loadingScreen');
            loadingScreen.style.display = 'none';
        }

        window.addEventListener('load', () => {
            document.querySelector(`#hdrButtons button[onclick*="${currentHDR}"]`)?.classList.add('active');
        });

        document.addEventListener('DOMContentLoaded', () => {
            const loadingScreen = document.getElementById('loadingScreen');
            loadingScreen.style.display = 'flex';
            requestAnimationFrame(() => {
                loadingScreen.classList.add('show');
            });
        });
    